import classNames from 'classnames';
import { IKContext, IKImage, IKVideo } from 'imagekitio-react';
import { array, bool, node, object, string } from 'prop-types';
import React from 'react';
import { isBookingProcessAlias } from '../../../transactions/transaction';
import { displayPrice } from '../../../util/configHelpers';
import { formatMoney } from '../../../util/currency';
import { createSlug } from '../../../util/urlHelpers';

import PhotoAlbum from 'react-photo-album';

import { NamedLink, PaginationLinks } from '../../../components';
import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';

import { Video } from 'lucide-react';
import css from './SearchResultsPanel.module.css';

const IMAGE_KIT_ENDPOINT = process.env.REACT_APP_IMAGE_KIT_ENDPOINT;
const MAX_MOBILE_SCREEN_WIDTH = 768;
const MIN_IMAGE_HEIGHT = 240;
const MAX_IMAGE_HEIGHT = 1024;
const MAX_IMAGE_WIDTH = 1024;
const MAX_PORTRAIT_IMAGE_RATIO = 3 / 4;
const MAX_LANDSCAPE_IMAGE_RATIO = 4 / 3;

const lqip = { active: true, quality: 20 };

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const formattedPrice = formatMoney(intl, price);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={formattedPrice}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    intl,
    config,
    listings,
    pagination,
    search,
    setActiveListing,
    isMapVariant,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  const MobileResults = props => {
    const { paginationLinks, photos } = props;

    return (
      <div className={css.mobileCards}>
        {photos.map(photo => (
          <NamedLink
            className={css.listingLink}
            name="ListingPage"
            params={{ id: photo.id, slug: photo.slug }}
            key={photo.id}
          >
            <div
              className={css.listingWrapper}
              style={{ aspectRatio: `${photo.width} / ${photo.height}` }}
            >
              <div className={css.imageWrapper}>
                {photo.isVideo ? (
                  <IKVideo
                    disablePictureInPicture
                    loop
                    muted
                    playsInline
                    autoPlay
                    className={css.ikImage}
                    path={photo.path + '/ik-video.mp4'}
                    poster={`${IMAGE_KIT_ENDPOINT}${photo.path}/ik-video.mp4/ik-thumbnail.jpg?tr=w-${photo.width},h-${photo.height},c-maintain_ratio`}
                    width={photo.width}
                    height={photo.height}
                    transformation={[
                      {
                        height: photo.height,
                        width: photo.width,
                        crop: 'maintain_ratio',
                      },
                    ]}
                  />
                ) : (
                  <IKImage
                    className={css.ikImage}
                    path={photo.path}
                    lqip={lqip}
                    width={photo.width}
                    height={photo.height}
                    transformation={[
                      {
                        height: photo.height,
                        width: photo.width,
                        crop: 'maintain_ratio',
                      },
                    ]}
                  />
                )}
                <div className={css.info}>
                  <PriceMaybe
                    price={photo.price}
                    publicData={photo.publicData}
                    config={config}
                    intl={intl}
                  />
                  <p className={css.title}>{photo.title}</p>
                  <p className={css.name}>{photo.providerName}</p>
                </div>
                {photo.isVirtual && (
                  <div className={css.virtual}>
                    <Video />
                  </div>
                )}
              </div>
            </div>
          </NamedLink>
        ))}
        {paginationLinks}
      </div>
    );
  };

  const DesktopResults = props => {
    const { paginationLinks, photos } = props;

    return (
      <div className={css.photoAlbum}>
        <PhotoAlbum
          layout={'rows'}
          spacing={12}
          photos={photos}
          rowConstraints={{ maxPhotos: 3 }}
          renderPhoto={({ photo, wrapperStyle }) => (
            <NamedLink
              className={css.listingLink}
              name="ListingPage"
              params={{ id: photo.id, slug: photo.slug }}
              key={photo.id}
              style={wrapperStyle}
            >
              <div className={css.imageWrapper}>
                {photo.isVideo ? (
                  <IKVideo
                    disablePictureInPicture
                    loop
                    muted
                    playsInline
                    autoPlay
                    className={css.ikImage}
                    path={photo.path + '/ik-video.mp4'}
                    poster={`${IMAGE_KIT_ENDPOINT}${photo.path}/ik-video.mp4/ik-thumbnail.jpg?tr=w-${photo.width},h-${photo.height},c-maintain_ratio`}
                    width={photo.width}
                    height={photo.height}
                    transformation={[
                      {
                        height: photo.height,
                        width: photo.width,
                        crop: 'maintain_ratio',
                      },
                    ]}
                  />
                ) : (
                  <IKImage
                    className={css.ikImage}
                    path={photo.path}
                    lqip={lqip}
                    width={photo.width}
                    height={photo.height}
                    transformation={[
                      {
                        height: photo.height,
                        width: photo.width,
                        crop: 'maintain_ratio',
                      },
                    ]}
                  />
                )}
                <div className={css.info}>
                  <PriceMaybe
                    price={photo.price}
                    publicData={photo.publicData}
                    config={config}
                    intl={intl}
                  />
                  <p className={css.title}>{photo.title}</p>
                  <p className={css.name}>{photo.providerName}</p>
                </div>
                {photo.isVirtual && (
                  <div className={css.virtual}>
                    <Video />
                  </div>
                )}
              </div>
            </NamedLink>
          )}
        />
        {paginationLinks}
      </div>
    );
  };

  const photos = listings.map(l => {
    const firstImage =
      l?.attributes?.publicData?.media && l.attributes.publicData.media?.length > 0
        ? l.attributes.publicData.media[0]
        : null;

    const isVideo = firstImage?.ik?.fileType === 'non-image' && 'videoCodec' in firstImage?.ik;
    const image = firstImage?.ik;
    const imageAspectRatio = image?.width / image?.height;

    let height = Math.max(MIN_IMAGE_HEIGHT, image?.height ?? 0);
    height = Math.min(MAX_IMAGE_HEIGHT, height ?? 0);

    let width = height * imageAspectRatio ?? 0;

    if (imageAspectRatio < MAX_PORTRAIT_IMAGE_RATIO) {
      height = MAX_IMAGE_HEIGHT;
      width = MAX_IMAGE_WIDTH;
    }

    if (imageAspectRatio > MAX_LANDSCAPE_IMAGE_RATIO) {
      height = MAX_IMAGE_HEIGHT;
      width = MAX_IMAGE_WIDTH;
    }

    const { title = '' } = l.attributes;

    return {
      id: l.id.uuid,
      path: image?.filePath,
      width: width,
      height: height,
      isVideo,
      isVirtual: l?.attributes?.publicData?.listingType === 'virtual',
      title: title,
      slug: createSlug(title),
      providerName: l?.author?.attributes?.profile?.displayName,
      price: l?.attributes?.price,
      publicData: l?.attributes?.publicData,
    };
  });

  const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
  const isMobileLayout = hasMatchMedia
    ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
    : true;

  return (
    <div className={classes}>
      <div className={isMapVariant ? css.listingCardsMapVariant : css.listingCards}>
        <IKContext urlEndpoint={IMAGE_KIT_ENDPOINT}>
          {isMobileLayout ? (
            <MobileResults photos={photos} paginationLinks={paginationLinks} />
          ) : (
            <DesktopResults photos={photos} paginationLinks={paginationLinks} />
          )}
        </IKContext>
        {props.children}
      </div>
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  isMapVariant: true,
};

SearchResultsPanel.propTypes = {
  children: node,
  className: string,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  isMapVariant: bool,
};

export default SearchResultsPanel;
